import Gridcom from "../src/components/Gridcom";
import Headcom from "../src/components/Headcom";
import FooterCom from "../src/components/FooterCom";
import BlackScreen from "../src/components/BlackScreen";
import Searchcom from "../src/components/Searchcom";
import { GameList } from "../src/list/GameList";
import { PageList } from "../src/list/PageList";
import { Assets } from "../src/list/Assets";
import { useRouter } from "next/router";
import Snow_fx from "../src/fx/Snow_fx";
import { Link } from "react-router-dom";
import CategoryGames  from '../src/components/CategoryGames.js'

function newgame(){

  var linknumnber = Math.floor(Math.random() * (100 - 1 + 1) ) + 1;
  console.log(linknumnber);
 
  var gamelink = GameList[linknumnber].path[0];
  console.log( GameList[linknumnber].path[0]);
  window.location.replace(gamelink);
}

export default function NotPage() {
  const path = useRouter();
  const pathName = path.query.id;

  const first50Games = GameList.slice(0, 50);
  return (
    <div>
      <Headcom
        title={PageList[0].title}
        s_dis={PageList[0].s_dis}
        path={PageList[0].path[1] + PageList[0].path[0]}
        thumb={PageList[0].thumb}
        logo_16={Assets[0].logo_16}
        logo_32={Assets[0].logo_32}
        logo_192={Assets[0].logo_192}
        logo_270={Assets[0].logo_270}
        logo_512={Assets[0].logo_512}
      />
      <Searchcom />
      <BlackScreen />
      <div className="game-root">
        <div className="container">
          {/* <!--Logo section start--> */}
          {/* <Logocom /> */}
         
          <div className="container-404" >
          <div className="contant name">
               <h1>Oops, this page does not exist</h1> 
               
            </div>
            <div className="img-404" >
            <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="100%"
                    height="100%"
                    src={Assets[0].image404}
                  ></img>
            </div>
            <a className="txt-none" onClick={newgame}>
            <div className="btn-404">
              
            <p className="btn-404" >Play a surprise game</p>
            </div></a>
            <div className="contant name">
            <p>It seems that you've arrived to a dead end!</p>
            
            </div>
          
            </div>
        </div>

        
        <CategoryGames games={first50Games} category={""} />
              
      </div>

     
      <FooterCom />
      <Snow_fx />
    </div>
  );
}
