import { Assets } from "../list/Assets";
import { GameList } from "../list/GameList";
import Gridcom from "./Gridcom";
import { searchInValue } from "./Logocom";

function recentGames() {
  var myArray = [];
  var i;
  for (i = 0; i < 6; i++) {
    myArray[i] = (
      <Gridcom
        key={i}
        myclass="thumb-1x1"
        name={GameList[i].name[0]}
        thumb={GameList[i].thumb}
        urlname={GameList[i].path[0]}
        onClick={search_close}
      />
    );
  }
  return myArray;
}

function search_result() {
  var input, filter, search_result, s_h2, Recent_games;
  var count = 0;
  var i;
  search_result = document.getElementsByClassName("Search_game_container");
  s_h2 = document.getElementsByClassName("s_h2");
  Recent_games = document.getElementsByClassName("Recent_games");
  search_result[0].style.display = "grid";
  input = document.getElementsByClassName("Search_Feild");

  filter = input[0].value.toUpperCase();
  while (search_result[0].lastElementChild) {
    search_result[0].removeChild(search_result[0].lastElementChild);
  }
  for (i = 0; i < GameList.length; i++) {
    if (
      GameList[i].name[0].toUpperCase().search(filter) > -1 &&
      filter.length > 0 &&
      filter != " "
    ) {
      count++;
      Recent_games[0].style.display = "none";
      var a = document.createElement("a");
      a.classList.add("s_thumb");
      var b = document.createElement("span");
      b.classList.add("s_title");
      var c = document.createElement("div");
      c.classList.add("s_cover");
      a.href = GameList[i].path[0];
      a.setAttribute("title", GameList[i].name[0]);
      b.innerHTML = GameList[i].name[0];
      a.appendChild(b);
      a.appendChild(c);

      var d = document.createElement("img");
      d.classList.add("thumb-img");
      d.setAttribute("decoding", "async");
      d.setAttribute("alt", GameList[i].name[0]);
      d.setAttribute("title", GameList[i].name[0]);
      d.src = GameList[i].thumb;
      a.appendChild(d);
      search_result[0].appendChild(a);
      s_h2[0].innerHTML = `${count}` + " " + "Games Found:";
    } else if (filter.length <= 0) {
      Recent_games[0].style.display = "grid";
      s_h2[0].innerHTML = "New Games";
      count = 0;
    } else if (search_result[0].childElementCount == 0) {
      s_h2[0].innerHTML = "Games Not Found:";
    }
  }
}

function search_close() {
  searchInValue(false);
  var logo_down = document.getElementsByClassName("logo-down");
  var Feild = document.getElementsByClassName("Search_Feild");
  var Recent_games = document.getElementsByClassName("Recent_games");
  var s_h2 = document.getElementsByClassName("s_h2");
  var Search_div = document.getElementsByClassName("Search_div");
  var black_screen = document.getElementById("black_screen");
  var count = 0;
  Feild[0].value = "";
  var search = document.getElementsByClassName("Search_game_container");
  while (search[0].lastElementChild) {
    search[0].removeChild(search[0].lastElementChild);
  }
  Recent_games[0].style.display = "grid";
  search[0].style.display = "none";

  s_h2[0].innerHTML = "New Games";
  count = 0;
  Search_div[0].className = "Search_div Sout";
  black_screen.style.display = "none";
  logo_down[0].className = "logo-down down backtodown";
  document.body.style.overflow = "auto";
}

export default function Searchcom(props) {
  return (
    <></>
    // <div className="Search_div">
    //   <div className="Search_container" role={"search"}>
    //     <div className="Search_input_div">
    //       <div className="Search_input">
    //         <input
    //           className="Search_Feild"
    //           placeholder="Enter Your Dream Game Name"
    //           type={"search"}
    //           onKeyUp={search_result}
    //         ></input>
    //       </div>
    //       <div className="Search_icon">
    //         <img
    //           className="s_icon"
    //           decoding="async"
    //           alt="search icon"
    //           title="search icon"
    //           src={Assets[0].s_icon}
    //         ></img>
    //       </div>
    //     </div>
    //     <div className="Search_close" onClick={search_close}>
    //       <img
    //         className="c_icon"
    //         decoding="async"
    //         alt="search close icon"
    //         title="search close icon"
    //         src={Assets[0].c_icon}
    //       ></img>
    //     </div>
    //   </div>
    //   <h2 className="s_h2">New Games</h2>
    //   <div className="Recent_games" style={{ display: "grid" }}>
    //     {recentGames()}
    //   </div>
    //   <div className="Search_game_container" style={{ display: "grid" }}></div>
    // </div>
  );
}
