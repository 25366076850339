import Link from "next/link";
import { Assets } from "../list/Assets";
import Image from "next/image";

export default function Gridcom(props) {
  const myImageLoader = ({}) => {
    return `${props.thumb}?w=${100}&q=${100}`;
  };
  const myImageLoaderNew = ({}) => {
    return `${Assets[0].new_l}?w=${100}&q=${100}`;
  };

  function newLabel(value) {
    var myArray = [];
    if (value <= 4) {
      myArray[value] = (
        <div className="new-badge">
          <Image
            loader={myImageLoaderNew}
            src={"image-src"}
            alt={"new"}
            layout="fill"
          />
        </div>
      );
    }
    return myArray;
  }
  return (
    <Link href={props.urlname} prefetch={false}>
      <a  className={"thumb "+ props.myclass}>
        <div className="thumb-container">
          <span className="title">{props.name}</span>
          <div className="cover"></div>
          {/* <img className="thumb-img" src={props.thumb} alt={props.name} /> */}
          <div  className="thumb-img" style={{width: '100%', height: '100%', position: 'relative'}}>
            <Image
              loader={myImageLoader}
              src={props.thumb}
              alt={props.name}
              layout="fill"
            />
          </div>
        </div>
        {newLabel(props.value)}
      </a>
    </Link>
  );
}
